.toolTipButton {
  border: none;
  background: transparent;
}
.toolTipButton:hover {
  cursor: pointer;
}
.customScroll::-webkit-scrollbar-track {
  background-color: red !important;
}

/* LOADER FROM VUE */
.icon-loader-wrap {
  position: relative;
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.logo-image-svg {
  position: absolute;
  background: transparent;
}

#svg {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  fill: #252525;
  width: 70% !important;
  height: 100%;
}

.icon-loader-wrap path:nth-child(1) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.05s;
}
.icon-loader-wrap path:nth-child(2) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.1s;
}
.icon-loader-wrap path:nth-child(3) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.15s;
}
.icon-loader-wrap path:nth-child(4) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.2s;
}
.icon-loader-wrap path:nth-child(5) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.25s;
}
.icon-loader-wrap path:nth-child(6) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.3s;
}
.icon-loader-wrap path:nth-child(7) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.35s;
}
.icon-loader-wrap path:nth-child(8) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.4s;
}
.icon-loader-wrap path:nth-child(9) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.45s;
}
.icon-loader-wrap path:nth-child(10) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.5s;
}

.icon-loader-wrap path:nth-child(11) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.65s;
}
.icon-loader-wrap path:nth-child(12) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.6s;
}
.icon-loader-wrap path:nth-child(13) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.65s;
}
.icon-loader-wrap path:nth-child(14) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.7s;
}
.icon-loader-wrap path:nth-child(15) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.75s;
}
.icon-loader-wrap path:nth-child(16) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.8s;
}
.icon-loader-wrap path:nth-child(17) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.85s;
}
.icon-loader-wrap path:nth-child(18) {
  animation: pweek 1s linear infinite;
  animation-delay: 0.9s;
}

@keyframes pweek {
  0% {
    fill: #e6616a;
  }
  20% {
    fill: #252525;
  }
  40% {
    fill: #e6616a;
  }
  60% {
    fill: #252525;
  }
  80% {
    fill: #e6616a;
  }
  100% {
    fill: #252525;
  }
}

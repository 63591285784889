.wrapper-class {
  width: 100%;
}

.rdw-editor-wrapper {
  margin: 1rem auto;
  padding: 5px;
}
.rdw-editor-wrapper > div {
  padding: 7.5px;
  padding-top: 10px;
}

.rdw-editor-main {
  padding-top: 5px;
  height: 150px !important;
  overflow-y: scroll;
}
.public-DraftStyleDefault-ltr {
  margin: 0;
}

.rdw-editor-wrapper > div {
  transition: 50ms;
  border-bottom: 1px solid lightgray;
}
.rdw-dropdown-wrapper .rdw-dropdown-selectedtext span {
  position: absolute;
  top: 35%;
  transform: translateY(-35%);
}
.rdw-fontsize-dropdown {
  min-width: 50px;
}
.rdw-block-dropdown,
.rdw-fontfamily-dropdown {
  width: 150px;
}
.nativeSelect .options {
  background: red !important;
  font-weight: 900;
}
.myClass {
  width: 100%;
}
.options {
  background: red !important;
}

.resetDateButton {
  background: transparent;
  border: none;
  border-radius: 5px;
  transition: 0.35s;
}
.resetDateButton:hover {
  cursor: pointer;
  background: rgb(240, 240, 240);
}
.resetDateButton:focus {
  outline: none;
  animation: myAnimation 0.2s forwards;
}

@keyframes myAnimation {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 10px 2px rgba(0, 0, 0, 0.12);
  }
}

div.pac-container.pac-logo {
  z-index: 9999;
}

.AcceptDataPrivacy-feel {
  color: rgb(240, 240, 240);
}

.markdown {
  font-size: medium;
  padding: 30px;
}

.markdown h1 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 20px 0px 10px 0;
}
.markdown h2 {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 20px 0px 10px 0;
}

.markdown h3 {
  font-size: 1rem;
  font-weight: 400;
  margin: 20px 0px 10px 0;
}

.markdown p {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 25px;
}

.markdown img {
  max-width: 100%;
  height: auto;
}

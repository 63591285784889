.scrollable::-webkit-scrollbar {
  margin-left: 5px;
  width: 6px;
  background-color: #f5f5f5;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(177, 177, 177);
}
